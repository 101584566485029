import React from "react";
import styled from "styled-components";
//
import SVGFacebook from "components/svg-facebook";
//
export default ({ ...rest }) => (
  <Wrap>
    <a
      href="https://www.facebook.com/groups/157857614408679/"
      target="_blank"
      title="Facebook"
    >
      <SVGFacebook />
    </a>
  </Wrap>
);
//
const Wrap = styled.div`
  align-self: center;
  justify-self: center;
  padding: 0.5rem;
`;
