import React from "react";
//
const SVGFacebook = ({ className }) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 425.2 425.2"
    >
      <title>Facebook</title>
      <path className="st0" d="M117.14,249.84" />
      <path
        className="st1"
        d="M204.34,399.09c8.81,0.21,5.85,0.21,8.81,0.21c100.42,0,181.82-81.4,181.82-181.82S313.56,35.66,213.15,35.66
		S31.33,117.07,31.33,217.48c0,80.66,52.53,149.06,125.24,172.84l-0.23,0.69c4.35-35.46,8.7-70.92,13.04-106.37
		c0.23-1.91,0.33-4.19-1.17-5.4c-1.01-0.81-2.41-0.84-3.7-0.84c-13.44,0.04-26.87,0.08-40.31,0.13c-1.77,0.01-3.79-0.1-4.91-1.48
		c-0.86-1.06-0.88-2.55-0.87-3.91c0.1-9.69,0.2-19.38,0.3-29.06c0.03-3.27,0.27-6.97,2.78-9.05c1.81-1.5,4.33-1.72,6.68-1.88
		c12.08-0.8,24.16-1.59,36.23-2.39c1.7-0.11,3.6-0.32,4.71-1.61c0.93-1.08,1.05-2.63,1.1-4.06c0.95-26.28-3.18-52.49-3.26-78.79
		c-0.08-26.29,4.42-53.83,20.38-74.73c3.07-4.02,6.59-7.8,10.88-10.48c6.03-3.76,13.27-5.17,20.38-5.34
		c20.92-0.5,41.64,9.6,54.84,25.84c13.2,16.24,18.86,38.15,16.09,58.9c-0.22,1.65-0.53,3.39-1.63,4.64
		c-1.27,1.45-3.29,1.92-5.17,2.32c-11.45,2.42-22.9,4.85-34.35,7.27c-1.22,0.26-2.51,0.51-3.68,0.08c-2.17-0.79-2.91-3.47-3.14-5.77
		c-0.56-5.4-0.09-10.95-1.48-16.2c-1.39-5.25-5.33-10.4-10.74-10.86c-5.05-0.43-9.67,3.45-11.75,8.07
		c-2.07,4.63-2.16,9.86-2.21,14.93c-0.27,27.07-0.55,54.15-0.82,81.22c15.17-1.5,30.34-3.01,45.51-4.51
		c5.97,22.75,7.37,46.7,4.08,69.99c-0.3,2.14-0.76,4.49-2.46,5.82c-1.83,1.43-4.42,1.18-6.72,0.86
		c-10.39-1.46-20.77-2.91-31.16-4.37c-1.7-0.24-3.52-0.46-5.04,0.34c-2.37,1.24-2.96,4.3-3.28,6.95
		c-3.52,29.25-7.04,58.49-10.56,87.74"
      />
    </svg>
  );
};

export default SVGFacebook;
