import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
//
export default ({ contact, name }) => (
  <Wrap>
    <Title>{name}</Title>
    <RichText {...contact} />
  </Wrap>
);
//
const Wrap = styled.div`
  padding: 0.5rem;
  width: 12rem;
`;
const Title = styled.h3`
  color: #000000;
  margin: 0;
  text-transform: uppercase;
`;
