import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
//
// this is only active when the location pathname is exactly
// the same as the href.
const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : null;
};

const ExactNavLink = props => <Link getProps={isActive} {...props} />;

// this link will be active when itself or deeper routes
// are current
const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: "active" } : null;
};

const PartialNavLink = props => (
  <Link getProps={isPartiallyActive} {...props} />
);

export default ({ path, title, ...rest }) => (
  <Wrap>
    <PartialNavLink to={path} title={title}>
      {title}
    </PartialNavLink>
  </Wrap>
);
//
const Wrap = styled.li`
  list-style: none;
  margin: 0;
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: #000000;
  display: inline-block;

  :last-child {
    border-style: none;
  }

  a {
    display: inline-block;
    margin: 0;
    padding: 0;
    position: relative;
  }
`;
