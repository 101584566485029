import React from "react";
import styled from "styled-components";
//
import Logo from "./logo";
import Navigation from "./menu";
//
export default ({ ...rest }) => (
  <Wrap>
    <Logo />
    <Navigation {...rest} />
  </Wrap>
);
//

const Wrap = styled.header`
  background-color: #6a7fbd;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1.5rem 0;
  top: 0;
  width: 100%;
  z-index: 2000;

  a {
    :hover,
    &.active {
      color: #f1f4f6;
    }
  }

  svg {
    display: block;
    height: 100%;
    fill: #f1f4f6;

    :hover {
      fill: #000000;
    }
  }
`;
