import React from "react";
import styled from "styled-components";
//
import Item from "./item";
import Facebook from "./facebook";
//
export default ({ title, items, links, ...rest }) => (
  <Wrap>
    <Items>
      <Title>{title}</Title>
      <Contact>
        {items.map((item, i) => (
          <Item key={i} {...item} />
        ))}
      </Contact>
      <Facebook />
    </Items>
    <Copyright>
      <p>
        KUVITUS <a href="mailto:tuulituulee@gmail.com">Tuuli Ritsilä</a>
      </p>
      <p>
        VERKKOSIVU <a href="mailto:aleksiritsila@gmail.com">Aleksi Ritsilä</a>
      </p>
    </Copyright>
  </Wrap>
);
//
const Wrap = styled.div`
  background-color: #E7B142;
  position: relative;
  text-align: center;
  width: 100%;

  svg {
    display: block;
    height: 5rem;
    fill: #ffffff;

    :hover {
      fill: #6a7fbd;
    }
`;

const Title = styled.h2`
  color: #ffffff;
  margin: 0 auto;
  padding: 0 1rem;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 1rem;

  @media (min-width: 768px) {
    flex-direction: column;
    padding: 1.5rem;
  }
`;

const Contact = styled.div`
  margin: 0 auto;
  padding: 1rem 0;
  p {
    color: #00000;
    margin: 0;
  }

  @media (min-width: 768px) {
    display: flex;
  }
`;

const Copyright = styled.div`
  display: flex;
  justify-content: center;

  p {
    margin: 0 0.5rem;
    font-size: 0.9em;
  }

  @media (min-width: 335px) {
    a {
      font-size: normal;
    }
  }
`;
