import React from "react";
//
const SVGLogo = ({ className }) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 75 425.2 150.46"
    >
      <title>Nurmin Majakka</title>
      <path
        d="M20.19,184.69c-0.01,0.08-0.01,0.1-0.09,0.2c-0.5,0.66-1.7,1.53-2.9-0.2c-0.02-0.03-0.1-0.18-0.11-0.25l0.27-3.65
				l0.05-0.92l0.03-0.46l0.11-2.15l0.13-3.11c0.27-8.29,0.27-16.65,0.37-24.99l0.51-50.06l0.13-12.51l0.02-1.56l0-0.2l0-0.1l0-0.05
				c1.37-0.12-3.08,0.19,3.08-0.27l0.06,0.39l0.13,0.77l0.5,3.08l1.04,6.16c1.43,8.2,2.98,16.39,4.66,24.54l0,0
				c3.35,16.16,7.17,32.29,11.56,48.14c1.16,3.89,2.56,7.69,4.71,10.94c0.28,0.39,0.55,0.8,0.84,1.18c0.31,0.37,0.6,0.75,0.92,1.1
				c0.33,0.34,0.64,0.71,0.99,1.02l0.43,0.38c0.01,0.01,0.01,0,0.01-0.01c-0.93-8.26-1.54-16.56-1.84-24.86
				c-0.61-16.61,0.03-33.27,1.9-49.79l0.76-6.19l0.65-4.59l0.13-0.91l0.29-1.82c0,0,0.28-1.16,0.66-2.72
				c0.38-1.56,0.93-3.52,1.47-5.11c1.66-5.43,1.93-3.07,1.67,0.41c0.07-0.17-0.18,1.9-0.38,4.02c-0.21,2.12-0.42,4.28-0.42,4.28
				c-0.1,0.58-0.19,1.17-0.27,1.75l-0.48,3.4c-0.3,1.98-0.52,3.97-0.77,5.96c-0.26,1.99-0.46,3.98-0.66,5.97
				c-1.61,15.93-2.06,31.98-1.36,47.98l0.32,6l0.44,5.99l0.55,5.98c0.08,1,0.21,1.99,0.32,2.99l0.23,2.05l0.17,1.5l0.08,0.75
				c0.02,0.24,0.02,0.44,0.04,0.67l0.06,1.32l0.03,0.66l0.02,0.33c0,0.05,0.01,0.12,0,0.16l-0.15-0.09l-2.4-1.49l-2.32-1.55
				c-0.2-0.14-0.39-0.29-0.57-0.47l-0.42-0.38c-0.2-0.19-0.41-0.36-0.6-0.55c-1.55-1.53-2.83-3.29-3.88-5.13
				c-2.11-3.69-3.41-7.65-4.53-11.56c-4.25-15.59-7.96-31.26-11.24-47.05l0,0c-1.68-8.19-3.24-16.4-4.68-24.64l-1.04-6.18l-0.5-3.09
				l-0.13-0.77l-0.06-0.39c6.18-0.45,1.71-0.1,3.09-0.19l0,0.05l0,0.1l0,0.2l-0.02,1.56l-0.13,12.51l-0.51,50.06
				c-0.11,8.34-0.1,16.68-0.38,25.07l-0.13,3.14l-0.11,2.18l-0.03,0.46l-0.06,0.93L20.19,184.69z"
      />
      <path
        d="M58.19,156.21c-0.07,0.04-0.1,0.06-0.23,0.05c-0.43,0.01-1.02-0.11-1.37-0.49c-0.36-0.38-0.48-1.01-0.19-1.96
				c0.01-0.04,0.07-0.18,0.12-0.23c1.33-0.85,2.5-1.86,3.71-3.1c0.87-0.89,1.71-1.87,2.57-2.89l2.6-3.16l1.29-1.57l0.64-0.78
				l0.32-0.39l0.08-0.1c0,0.01-0.01,0.07-0.02,0.1l-0.04,0.22l-0.72,3.69l-5.73,29.37c-0.41,2.05-0.7,4.11-0.23,5.69
				c0.23,0.76,0.66,1.37,1.22,1.76c0.56,0.36,1.28,0.53,2.08,0.43c0.79-0.07,1.61-0.41,2.33-0.87c0.35-0.24,0.69-0.51,0.96-0.82
				c0.29-0.29,0.51-0.63,0.75-1.03c0.91-1.56,1.28-3.52,1.43-5.58c0.14-2.06,0.17-4.27,0.26-6.43l0.22-6.5l0.22-6.5l0.05-1.62l0-0.1
				l0-0.05l0-0.03c-2.76,0,3.76-0.03,3.09-0.02l0.01,0.2l0.02,0.39l0.04,0.79c0.07,1.06,0.16,2.12,0.27,3.18
				c0.22,2.13,0.49,4.26,0.79,6.4l0.9,6.27l0.4,2.81c0,0,0.36,4.76,0.19,8.14c-0.08,2.86-0.44,3.72-0.73,3.43
				c-0.32-0.28-0.65-1.68-0.99-3.39c-0.02,0.16-0.27-1.41-0.62-3.21c-0.35-1.8-0.75-3.84-0.9-4.62l0,0
				c-0.04-0.21-0.07-0.33-0.07-0.33l-0.55-3.85c-0.61-4.21-1.24-8.44-1.63-12.75c-0.1-1.08-0.17-2.17-0.22-3.26l0-0.05l0-0.03
				c-0.68-0.01,5.89-0.02,3.11,0l0,0.1l-0.01,0.2l-0.01,0.4l-0.03,0.8l-0.05,1.6l-0.21,6.38l-0.21,6.38
				c-0.09,2.13-0.08,4.23-0.32,6.49c-0.24,2.23-0.79,4.64-2.28,6.73c-0.79,1.08-1.8,1.93-2.94,2.52c-1.14,0.6-2.43,0.97-3.81,0.95
				c-1.37,0.01-2.86-0.53-3.94-1.52c-0.52-0.49-0.96-1.07-1.29-1.69c-0.32-0.62-0.52-1.27-0.66-1.91c-0.48-2.56,0.03-4.85,0.42-6.94
				l4.13-21.21l0,0C61.2,153.94,59.76,155.22,58.19,156.21z"
      />
      <path
        d="M82.59,152.36c-0.03,0.04-0.04,0.05-0.15,0.05c-0.31,0.02-0.81,0.07-1.31-0.06c-0.47-0.14-1.08-0.51-0.94-1.79
				c0.01-0.05,0.07-0.24,0.12-0.33c0.2-0.21,0.41-0.4,0.63-0.59c0.34-0.26,0.69-0.5,1.07-0.69c0.74-0.38,1.51-0.58,2.21-0.64
				l1.06-0.08c0.17,0,0.4-0.06,0.53-0.01l0.03,0.56l0.11,2.24c0.01,0.41,0.06,0.88,0.04,1.25L85.97,153l-0.06,1.45l-0.09,2.91
				c-0.05,1.94-0.06,3.88-0.06,5.82c0.02,3.88,0.13,7.76,0.35,11.63c0.11,1.94,0.25,3.87,0.41,5.8l0.26,2.9l0.15,1.45l0.04,0.36
				l0,0.01c2.76-0.03-3.75,0.06-3.08,0.05l0-0.02l0-0.05l0.01-0.09l0.01-0.18l0.06-0.73l0.93-11.68l0,0l0.72-8.99l0.36-4.49
				c0.12-1.5,0.24-3.04,0.5-4.61c0.26-1.57,0.66-3.17,1.36-4.7c0.35-0.78,0.79-1.52,1.29-2.22c0.26-0.35,0.53-0.68,0.82-1l0.45-0.46
				c0.73-0.72,1.58-1.34,2.53-1.81c0,0,0.32-0.15,0.9-0.33c0.59-0.16,1.41-0.35,2.41-0.31c1.01,0.03,2.1,0.34,3.03,0.88
				c0.93,0.54,1.71,1.3,2.22,2.11c0.89,1.38,0.84,2.52,0.7,3.06c-0.17,0.56-0.35,0.66-0.48,0.51c-0.27-0.31-0.49-1.6-1.62-2.55
				c0.03,0.07-0.23-0.22-0.76-0.56c-0.52-0.34-1.33-0.71-2.16-0.77c-0.42-0.05-0.8-0.02-1.17,0.06c-0.39,0.07-0.74,0.2-1.03,0.32
				c-0.58,0.26-0.94,0.51-0.94,0.51c-0.29,0.17-0.58,0.35-0.85,0.58c-0.14,0.1-0.27,0.22-0.4,0.34c-0.07,0.06-0.13,0.11-0.19,0.18
				l-0.29,0.29c-1.69,1.79-2.47,4.33-2.85,7.04c-0.35,2.72-0.5,5.68-0.76,8.54L88,172.87l0,0l-0.93,11.68l-0.06,0.73l-0.01,0.18
				l-0.01,0.09l0,0.05l0,0.02c0.67,0-5.87,0.07-3.1,0.04l0-0.01l-0.04-0.37l-0.15-1.47l-0.27-2.94c-0.16-1.96-0.3-3.92-0.42-5.89
				c-0.23-3.93-0.34-7.86-0.36-11.8c0-1.97,0.01-3.93,0.06-5.9l0.09-2.95l0.1-2.27L82.59,152.36z"
      />
      <path
        d="M110.2,184.17c-0.02,0.07-0.03,0.1-0.12,0.18c-0.6,0.59-1.92,1.23-2.83-0.67c-0.02-0.03-0.07-0.19-0.07-0.26
					c0.41-1.63,0.75-3.29,1.08-4.97c0.63-3.18,1.2-6.42,2-9.66c0.81-3.23,1.85-6.5,3.56-9.54c0.82-1.53,1.92-2.94,3.12-4.25
					c0.64-0.62,1.3-1.22,1.99-1.78l1.1-0.76l0.65-0.45c0.15-0.15,0.37-0.17,0.56-0.21l0.45-0.09c0.96-0.17,2.04-0.24,3.17,0.11
					c1.14,0.33,2.14,1.2,2.71,2.12c1.15,1.88,1.28,3.75,1.37,5.49c0.06,1.76-0.09,3.45-0.28,5.13c-0.1,0.83-0.23,1.66-0.36,2.49
					l-0.22,1.28c0,0.01,0,0,0,0c-0.01-0.01,0.02,0,0,0l0,0l-0.57-1c0.06,0.1,0.13,0.17,0.21,0.25c0.08,0.1,0.17,0.19,0.27,0.27
					l0.3,0.26c0.08,0.06,0.04,0.03,0.05,0.04l0,0c-0.07,0.09-0.22,0.41-0.32,0.7c-0.1,0.3-0.19,0.63-0.25,0.97
					c-0.03,0.17-0.06,0.34-0.08,0.51l0,0.02c2.61,0.78-3.56-1.05-2.92-0.87l0.01-0.03l0.03-0.06l0.05-0.12l0.11-0.25
					c0.14-0.33,0.3-0.65,0.39-1.01c0.47-1.38,0.72-2.9,0.96-4.52c0.25-1.61,0.49-3.35,1.2-5.11c0.68-1.77,2.05-3.44,3.68-4.41
					c0.39-0.3,0.82-0.45,1.24-0.67c0.42-0.21,0.84-0.41,1.27-0.53l1.28-0.42c0.21-0.06,0.43-0.1,0.64-0.15l0.93-0.2l0,0l1.28-0.27
					c0.21-0.03,0.44-0.12,0.62-0.11l0.26,0.6l0.9,2.09c0.41,0.84,0.62,1.76,0.9,2.65c0.15,0.44,0.24,0.9,0.32,1.36l0.25,1.37
					c0.13,0.92,0.18,1.84,0.25,2.76c0.12,3.68-0.42,7.25-1.03,10.7l-0.9,5.12l-0.31,2.13c-0.09,0.68-0.14,1.35-0.22,2.03
					c0,0-0.07,0.28-0.11,0.75c-0.06,0.47-0.14,1.14-0.24,1.9c-0.03,0.76-0.14,1.63-0.14,2.5c0,0.41,0.01,0.92-0.01,1.26
					c-0.05,0.27-0.02,0.33,0.24,0.63c1.82,1.97,2.78,1.9,2.48,2.27c-0.16,0.15-0.72,0.25-1.41,0.07c-0.69-0.17-1.49-0.56-2.27-1.1
					c0.02,0.03-0.02,0.02-0.31-0.21c-0.13-0.11-0.3-0.24-0.48-0.41c-0.13-0.07-0.1-0.22-0.14-0.36c-0.02-0.14-0.05-0.28-0.08-0.44
					c-0.08-0.42-0.17-0.91-0.27-1.45c-0.11-0.51-0.14-1.06-0.2-1.62c-0.24-2.23-0.11-4.49-0.11-4.49c0.08-0.69,0.12-1.38,0.23-2.06
					l0.1-0.68c0.11-0.84,0.25-1.67,0.4-2.49l0.86-4.89c0.55-3.22,0.97-6.42,0.78-9.53c-0.11-1.55-0.32-3.09-0.75-4.56
					c-0.22-0.73-0.41-1.48-0.75-2.17l-0.09-0.2l0,0l0,0l0.01,0l0.01,0l-0.28,0.06c-0.18,0.04-0.37,0.07-0.55,0.12l-1.04,0.35
					c-0.36,0.1-0.68,0.25-0.99,0.41c-0.3,0.17-0.66,0.27-0.91,0.49c-1.16,0.71-1.97,1.69-2.48,2.97c-1.05,2.57-0.93,6.03-2.09,9.45
					c-0.12,0.43-0.32,0.85-0.49,1.27l-0.14,0.32l-0.07,0.16l-0.03,0.08l-0.02,0.04c0.63,0.2-5.56-1.67-2.94-0.89l0-0.02
					c0.03-0.23,0.06-0.46,0.11-0.7c0.09-0.47,0.2-0.93,0.36-1.4c0.18-0.48,0.34-0.93,0.74-1.51c0.15-0.17,0.19-0.3,0.59-0.6
					c0.35-0.26,0.43-0.11,0.37,0.01c-0.09,0.25-0.36,0.56-0.62,0.99c-0.07,0.11-0.13,0.23-0.19,0.34c-0.09,0.11-0.34,0.2-0.5,0.3
					l-0.27,0.15l-0.14,0.07c-0.04,0.03-0.02,0.08-0.04,0.11l-0.09,0.48l-0.31,1.6l-0.38,1.93l-0.03,0.16l-0.02,0.08l-0.01,0.04
					l0.01-0.07l0.12-0.6l0.24-1.21l0.45-2.37l0.2-1.18c0.13-0.79,0.24-1.57,0.34-2.36c0.19-1.57,0.31-3.14,0.26-4.64
					c-0.04-1.48-0.29-3-0.88-3.92c-0.3-0.45-0.61-0.72-1.04-0.84c-0.43-0.14-1.02-0.14-1.66-0.02c-0.23,0.05-0.13,0.02-0.15,0.03
					l-0.36,0.25l-0.91,0.63c-0.57,0.47-1.12,0.97-1.65,1.48c-1,1.1-1.93,2.28-2.65,3.63c-1.5,2.65-2.48,5.66-3.25,8.75
					c-0.77,3.1-1.33,6.3-1.97,9.52C110.98,180.76,110.64,182.46,110.2,184.17z"
      />
      <path
        d="M107.29,153.97c-0.04,0.06-0.05,0.08-0.16,0.11c-0.35,0.15-0.88,0.29-1.38,0.17c-0.5-0.13-1-0.54-1.12-1.65
					c-0.01-0.04,0-0.22,0.03-0.29c0.51-0.8,1.08-1.56,1.72-2.26l0.49-0.52c0.06-0.06,0.21-0.22,0.38-0.37l0.47-0.43
					c0.64-0.55,1.29-1.04,1.93-1.48l1.07-0.75l0.27-0.19l0.14-0.1l0.07-0.05c0.02-0.02,0.02,0.04,0.03,0.06l0.14,0.65l0.57,2.59
					c0.09,0.43,0.2,0.86,0.27,1.32l0.11,0.94c0.05,0.49,0.12,0.98,0.16,1.47c0.12,1.97,0.18,3.94,0.05,5.88
					c-0.19,3.9-0.82,7.72-1.5,11.47l-0.53,2.84c0,0-1.22,4.57-2.14,7.75c-0.71,2.72-1.1,3.54-1.38,3.21
					c-0.25-0.32-0.4-1.8-0.31-3.56c-0.07,0.18,0.09-1.93,0.34-4.07c0.26-2.13,0.52-4.3,0.52-4.3l0.45-2.42
					c0.67-3.71,1.28-7.4,1.45-11.07c0.12-1.84,0.06-3.66-0.05-5.48l-0.2-1.83l-0.48,0.5C108.17,152.7,107.7,153.31,107.29,153.97z"
      />
      <path
        d="M146.77,156.22c0-0.08,0.01-0.1,0.09-0.2c0.47-0.66,1.65-1.6,2.91,0.1c0.02,0.03,0.1,0.18,0.11,0.25l-0.25,5.15
					l-0.76,15.6l-0.14,2.87c0,0-0.15,1.15-0.35,2.76c-0.1,0.76-0.19,1.57-0.11,2.27c0.04,0.35,0.13,0.67,0.26,0.96
					c0.03,0.07,0.08,0.14,0.11,0.21c0.04,0.04,0.06,0.1,0.1,0.14c0.08,0.09,0.18,0.19,0.31,0.26c0.43,0.24,0.93,0.28,1.28,0.23
					c0.37-0.05,0.59-0.2,0.77-0.35c0.3-0.33,0.42-0.55,0.57-0.38c0.05,0.09,0.14,0.28,0.09,0.56c-0.03,0.28-0.19,0.66-0.51,0.95
					c-0.64,0.6-1.73,0.92-2.92,0.55c0.06,0.03-0.08,0-0.35-0.12c-0.14-0.07-0.31-0.16-0.5-0.31c-0.2-0.16-0.38-0.33-0.54-0.53
					c-0.34-0.4-0.64-0.95-0.83-1.54c-0.19-0.59-0.3-1.22-0.34-1.81c-0.09-1.21-0.06-2.23-0.08-3.06c-0.01-0.81-0.02-1.36-0.02-1.36
					l0.12-2.46l0.75-15.6L146.77,156.22z"
      />
      <path
        d="M149.05,132.18c0.01-0.02,0.01-0.03,0.09-0.04c0.47-0.09,1.62-0.05,2.83,0.67c0.02,0.01,0.1,0.07,0.11,0.09
					c-0.01,0.02-0.12,0.48-0.25,1.16c-0.14,0.68-0.28,1.58-0.37,2.49c-0.2,1.81-0.14,3.63-0.14,3.63s-0.28,1.18-0.66,2.08
					c-0.49,1.51-1.24,1.06-1.71,0.14c-0.03,0.05-0.23-0.5-0.41-1.07c-0.18-0.58-0.32-1.18-0.32-1.18s-0.06-1.99,0.15-3.97
					c0.1-0.99,0.26-1.98,0.41-2.73C148.92,132.71,149.04,132.21,149.05,132.18z"
      />
      <path
        d="M155.89,165.11c-0.06,0.05-0.08,0.07-0.21,0.08c-0.41,0.07-1.03,0.07-1.44-0.25c-0.41-0.33-0.64-0.9-0.52-1.91
				c0-0.04,0.05-0.19,0.09-0.25c1.23-1.11,2.47-2.32,3.69-3.51l2.53-2.5l0.86-0.86l0.43-0.43l0.21-0.21l0.05-0.05
				c0.01,0.01,0.02,0.04,0.03,0.05l0.06,0.12l0.98,1.99c0.3,0.65,0.6,1.38,0.82,2.09c0.14,0.42,0.15,0.52,0.22,0.78
				c0.05,0.23,0.1,0.47,0.13,0.73l0.04,0.41l0,0.45l-0.03,0.58l-0.07,1.16l-0.27,4.63l-0.54,9.26l-0.27,4.63l-0.13,2.32l-0.01,0.14
				c0.67,0.09-5.8-0.78-3.06-0.41l0-0.01l0-0.02l0.01-0.04l0.02-0.07l0.06-0.29l0.13-0.58l0.26-1.15c0.72-3.06,1.59-6.08,2.61-9.06
				l0,0c0.75-2.2,1.58-4.37,2.48-6.51c0.45-1.07,0.93-2.13,1.42-3.18c0.49-1.05,1.02-2.18,1.84-3.26c0.41-0.54,0.89-1.07,1.47-1.52
				c0.55-0.45,1.33-0.86,2.07-1.04c0.75-0.2,1.54-0.25,2.34-0.12c0.8,0.12,1.63,0.46,2.33,1.09c0.7,0.62,1.13,1.52,1.28,2.27
				c0.17,0.76,0.19,1.41,0.22,1.97l0.2,3.42l0.78,13.69l0.19,3.4c0.03,0.57,0.07,1.12,0.12,1.66c0,0,0.01,0.28,0.02,0.74
				c0.02,0.46,0.06,1.1,0.17,1.8c0.11,0.7,0.3,1.49,0.62,2.18c0.32,0.71,0.79,1.38,1.31,1.9c0.91,0.86,1.73,1.14,2.21,1.33
				c0.49,0.17,0.66,0.3,0.52,0.47c-0.14,0.15-0.61,0.35-1.33,0.33c-0.71-0.01-1.64-0.26-2.5-0.8c0.08,0.07-0.42-0.19-1.07-0.8
				c-0.65-0.59-1.43-1.62-1.9-2.73c-0.5-1.11-0.77-2.28-0.94-3.13c-0.16-0.86-0.23-1.42-0.23-1.42l-1.11-19.25l-0.19-3.29
				c-0.04-0.49-0.11-0.87-0.22-1.1c-0.11-0.22-0.22-0.35-0.44-0.48c-0.43-0.25-1.25-0.28-1.89-0.02c-0.58,0.23-1.26,0.85-1.76,1.6
				c-0.53,0.77-0.97,1.7-1.43,2.71c-0.46,0.99-0.9,1.99-1.32,2.99c-0.85,2.01-1.62,4.05-2.33,6.12l0,0
				c-0.98,2.88-1.83,5.81-2.52,8.77l-0.26,1.11l-0.12,0.56l-0.06,0.28l-0.02,0.07l-0.01,0.03l0,0.02l0,0.01
				c2.75,0.37-3.75-0.49-3.08-0.41l0.01-0.14l0.13-2.32l0.27-4.63l0.54-9.26l0.27-4.63l0.07-1.16l0.03-0.58l0-0.13l-0.02-0.17
				c-0.02-0.13-0.04-0.26-0.08-0.41l-0.09-0.34l0-0.01l-0.9,0.89C158.44,162.72,157.21,163.92,155.89,165.11z"
      />
      <path
        d="M229.58,184.34c0,0.08,0,0.1-0.08,0.2c-0.44,0.68-1.58,1.67-2.91,0.03c-0.02-0.03-0.11-0.17-0.12-0.24
				c0-0.1,0.02-1.99,0.04-4.78c0.01-0.67,0.02-1.35,0.03-2.04c0.04-2.09,0.08-4.21,0.12-6.37c0.09-4.32,0.25-8.77,0.42-13.35
				c0.37-9.15,0.89-18.76,1.53-28.68c0.34-4.96,0.68-9.99,1.03-15.09c0.56-7.58,1.12-15.24,1.68-22.96l0.29-3.88l0.07-0.97
				l0.02-0.24c0-0.15,0.02-0.03,0.04,0.05l0.11,0.47l0.44,1.9c0.59,2.53,1.18,5.07,1.78,7.61c0.97,4.16,1.94,8.32,2.91,12.5
				c1.19,5.12,2.39,10.26,3.59,15.39c0.6,2.57,1.2,5.13,1.79,7.7l0.71,3.04l0,0.01c0.64-1.26,1.28-2.51,1.91-3.76
				c1.16-2.33,2.3-4.65,3.4-6.99c2.21-4.66,4.29-9.34,6.26-14c0.98-2.33,1.93-4.65,2.85-6.97c0.45-1.16,0.91-2.32,1.35-3.47
				l0.19-0.49c0.11-0.26,0.24-0.42,0.35-0.64l0.71-1.21l0.71-1.21c0.26-0.32,0.38-1.08,0.9-0.65c0.42,0.17,0.81,0.3,1.18,0.53
				c0.36,0.25,0.69,0.55,0.98,0.94c0.58,0.77,0.97,1.95,1.09,3.14c0.14,1.46,0.05,2.58,0.08,3.81c0.01,2.42,0.01,4.82,0.02,7.19
				c0.01,4.74,0.03,9.38,0.04,13.9c0.03,9.03,0.05,17.56,0.07,25.43c0.04,13.29,0.06,22.13,0.06,22.13s-0.3,4.73-0.77,8.05
				c-0.65,5.63-1.34,3.38-1.72-0.1c-0.07,0.36-0.61-8.34-0.61-8.34s-0.03-10.26-0.08-25.68c-0.02-7.76-0.05-16.17-0.07-25.08
				c-0.01-4.45-0.03-9.02-0.04-13.7c0-1.17-0.01-2.34-0.01-3.53l-0.01-1.78l0-0.89c0-0.27-0.01-0.53-0.03-0.78l-0.03-0.3l0,0
				c-0.43,1.1-0.86,2.21-1.29,3.32c-0.46,1.14-0.92,2.29-1.38,3.44c-0.93,2.3-1.9,4.6-2.89,6.91c-2,4.61-4.1,9.25-6.33,13.87
				c-1.11,2.31-2.27,4.62-3.43,6.92c-0.95,1.82-1.9,3.65-2.85,5.47l-0.9,1.71l-0.45,0.85l-0.22,0.43l-0.11,0.21
				c-0.04,0.07-0.08,0.19-0.09,0.05l-0.88-3.75c-1.34-5.75-2.69-11.53-4.04-17.32c-1.24-5.33-2.49-10.67-3.71-15.93
				c-0.23,3.2-0.46,6.38-0.68,9.53c-0.34,5.02-0.67,9.98-1,14.86c-0.61,9.77-1.13,19.22-1.47,28.21c-0.17,4.49-0.31,8.87-0.4,13.11
				c-0.05,2.6-0.09,5.12-0.13,7.55C229.59,182.38,229.58,184.24,229.58,184.34z"
      />

      <path
        d="M310.94,158.39c-0.01,0.06,0,0.09-0.08,0.17c-0.24,0.28-0.62,0.65-1.12,0.8c-0.49,0.14-1.14,0.06-1.79-0.9
				c-0.02-0.04-0.11-0.2-0.11-0.29c0.15-1.98,0.79-3.97,2.09-5.62c2.55-3.6,8.81-4.27,11.65-0.06c0.65,1.01,0.92,2.1,1.08,3.07
				c0.16,0.98,0.21,1.85,0.3,2.77c0.15,1.81,0.19,3.64,0.15,5.45c-0.08,3.64-0.5,7.27-1.28,10.82c-0.39,1.78-0.87,3.53-1.44,5.26
				c-0.27,0.85-0.57,1.76-1.06,2.71c-0.49,0.94-1.22,1.93-2.36,2.58c-1.12,0.63-2.43,0.78-3.57,0.6c-1.16-0.18-2.19-0.66-3.09-1.31
				c-0.9-0.66-1.66-1.52-2.19-2.56c-0.51-1.09-0.69-2.12-0.73-3.21c-0.05-2.16,0.66-4.13,1.61-5.84c0.93-1.69,2.29-3.33,4.03-4.43
				c0.87-0.56,1.85-1.02,2.97-1.25c1.11-0.22,2.44-0.13,3.59,0.46c1.14,0.56,1.99,1.58,2.46,2.66c0.48,1.08,0.62,2.11,0.71,3.06
				l0.09,1.21l0.04,0.75c0.03,0.5,0.07,0.96,0.12,1.41c0,0,0.01,0.27,0.06,0.68c0.04,0.42,0.13,0.94,0.38,1.55
				c0.45,1.23,1.62,2.64,2.93,3.43c1.11,0.65,1.97,0.86,2.47,1.04c0.51,0.16,0.67,0.31,0.53,0.47c-0.14,0.14-0.6,0.33-1.28,0.38
				c-0.68,0.05-1.58-0.04-2.51-0.36c0.09,0.05-0.43-0.1-1.2-0.51c-0.77-0.41-1.75-1.14-2.55-2.11c-0.81-0.95-1.37-2.17-1.62-3.11
				c-0.25-0.92-0.32-1.5-0.32-1.5c-0.06-0.54-0.09-1.05-0.12-1.56l-0.04-0.73l-0.05-0.69c-0.03-0.4-0.09-0.78-0.15-1.13
				c-0.13-0.72-0.37-1.26-0.7-1.65c-0.35-0.4-0.78-0.66-1.28-0.72c-1.01-0.17-2.44,0.43-3.56,1.36c-0.57,0.47-1.06,0.99-1.51,1.61
				c-0.46,0.63-0.86,1.3-1.18,1.98c-0.65,1.37-0.96,2.85-0.76,4.17c0.06,0.32,0.13,0.66,0.25,0.95c0.11,0.27,0.24,0.54,0.42,0.78
				c0.36,0.5,0.86,0.94,1.42,1.24c1.15,0.62,2.42,0.62,3.13-0.04c0.82-0.66,1.33-2.25,1.85-3.89c0.5-1.61,0.93-3.25,1.27-4.9
				c0.7-3.31,1.07-6.68,1.11-10.06c0.02-1.69-0.03-3.38-0.17-5.06c-0.15-1.71-0.29-3.38-0.95-4.25c-0.6-0.9-1.91-1.44-3.22-1.37
				c-1.32,0.04-2.45,0.68-3.31,1.77C311.53,155.53,311.04,156.95,310.94,158.39z"
      />

      <path
        d="M273.65,159.02c-0.01,0.06,0,0.09-0.08,0.17c-0.24,0.28-0.62,0.65-1.12,0.8c-0.49,0.14-1.14,0.06-1.79-0.9
				c-0.02-0.04-0.11-0.2-0.11-0.29c0.15-1.98,0.79-3.97,2.09-5.62c2.55-3.6,8.81-4.27,11.65-0.06c0.65,1.01,0.92,2.1,1.08,3.07
				c0.16,0.98,0.21,1.85,0.3,2.77c0.15,1.81,0.19,3.64,0.15,5.45c-0.08,3.64-0.5,7.27-1.28,10.82c-0.39,1.78-0.87,3.53-1.44,5.26
				c-0.27,0.85-0.57,1.76-1.06,2.71c-0.49,0.94-1.22,1.93-2.36,2.58c-1.12,0.63-2.43,0.78-3.57,0.6c-1.16-0.18-2.19-0.66-3.09-1.31
				c-0.9-0.66-1.66-1.52-2.19-2.56c-0.51-1.09-0.69-2.12-0.73-3.21c-0.05-2.16,0.66-4.13,1.61-5.84c0.93-1.69,2.29-3.33,4.03-4.43
				c0.87-0.56,1.85-1.02,2.97-1.25c1.11-0.22,2.44-0.13,3.59,0.46c1.14,0.56,1.99,1.58,2.46,2.66c0.48,1.08,0.62,2.11,0.71,3.06
				l0.09,1.21l0.04,0.75c0.03,0.5,0.07,0.96,0.12,1.41c0,0,0.01,0.27,0.06,0.68c0.04,0.42,0.13,0.94,0.38,1.55
				c0.45,1.23,1.62,2.64,2.93,3.43c1.11,0.65,1.97,0.86,2.47,1.04c0.51,0.16,0.67,0.31,0.53,0.47c-0.14,0.14-0.6,0.33-1.28,0.38
				c-0.68,0.05-1.58-0.04-2.51-0.36c0.09,0.05-0.43-0.1-1.2-0.51c-0.77-0.41-1.75-1.14-2.55-2.11c-0.81-0.95-1.37-2.17-1.62-3.11
				c-0.25-0.92-0.32-1.5-0.32-1.5c-0.06-0.54-0.09-1.05-0.12-1.56l-0.04-0.73l-0.05-0.69c-0.03-0.4-0.09-0.78-0.15-1.13
				c-0.13-0.72-0.37-1.26-0.7-1.65c-0.35-0.4-0.78-0.66-1.28-0.72c-1.01-0.17-2.44,0.43-3.56,1.36c-0.57,0.47-1.06,0.99-1.51,1.61
				c-0.46,0.63-0.86,1.3-1.18,1.98c-0.65,1.37-0.96,2.85-0.76,4.17c0.06,0.32,0.13,0.66,0.25,0.95c0.11,0.27,0.24,0.54,0.42,0.78
				c0.36,0.5,0.86,0.94,1.42,1.24c1.15,0.62,2.42,0.62,3.13-0.04c0.82-0.66,1.33-2.25,1.85-3.89c0.5-1.61,0.93-3.25,1.27-4.9
				c0.7-3.31,1.07-6.68,1.11-10.06c0.02-1.69-0.03-3.38-0.17-5.06c-0.15-1.71-0.29-3.38-0.95-4.25c-0.6-0.9-1.91-1.44-3.22-1.37
				c-1.32,0.04-2.45,0.68-3.31,1.77C274.24,156.17,273.76,157.59,273.65,159.02z"
      />

      <path
        d="M291.32,163.26c-0.07,0.03-0.1,0.04-0.22,0.01c-0.85-0.14-2.11-0.88-1.11-2.69c0.02-0.03,0.11-0.17,0.17-0.2
					c1.52-0.61,2.97-1.33,4.29-2.22c0.57-0.37,1.1-0.78,1.62-1.2l0.38-0.32l0.53-0.47c0.53-0.5,1.04-1.06,1.56-1.64l1.58-1.85
					l0.78-0.91l0.38-0.45l0.09-0.11c0.01,0.02,0.01,0.08,0.01,0.11l0.02,0.26l0.3,4.33l0.13,1.89l0.07,1.34l0.09,1.92
					c0.03,1.28,0.08,2.56,0.09,3.84c0.02,2.56-0.01,5.12-0.11,7.68c-0.18,5.12-0.64,10.21-1.2,15.27
					c-0.56,5.07-1.27,10.1-1.99,15.13c-0.17,1.24-0.37,2.58-0.7,3.9c-0.17,0.66-0.36,1.33-0.63,2c-0.23,0.62-0.72,1.53-0.76,1.57
					c-0.54,0.92-1.31,1.77-2.18,2.41c0,0-0.3,0.2-0.83,0.47c-0.26,0.14-0.58,0.27-0.93,0.41c-0.38,0.13-0.8,0.27-1.28,0.38
					c-0.94,0.21-2.2,0.22-3.31-0.3c-1.12-0.51-1.87-1.55-2.16-2.49c-0.51-1.63-0.02-2.72,0.33-3.17c0.4-0.45,0.61-0.44,0.66-0.24
					c0.07,0.21-0.01,0.59,0.04,1.1c0.04,0.5,0.21,1.14,0.58,1.69c-0.08-0.15,0.81,1.39,2.35,1.05c0.74-0.11,1.54-0.51,2.12-0.9
					c0.57-0.37,0.85-0.67,0.85-0.67c0.26-0.21,0.48-0.44,0.69-0.7c0.1-0.14,0.21-0.26,0.29-0.41l0.14-0.22
					c0.02-0.03,0.05-0.07,0.07-0.11l0.16-0.3c0.46-0.92,0.76-2.02,1-3.16c0.24-1.13,0.39-2.35,0.57-3.59
					c0.7-4.92,1.38-9.83,1.91-14.74c0.54-4.92,0.95-9.84,1.12-14.76c0.1-2.46,0.12-4.92,0.1-7.38l-0.09-3.69l-0.11-2.36l0,0
					l-0.28,0.25l-0.45,0.38c-0.6,0.49-1.23,0.97-1.88,1.39C294.63,161.77,292.99,162.59,291.32,163.26z"
      />

      <path
        d="M300.87,118.73c0.01-0.01,0.01-0.01,0.07,0.03c0.4,0.21,1.25,0.95,2.19,1.91c0.02,0.02,0.08,0.08,0.09,0.09
					c0,0-0.22,0.24-0.33,0.56c-0.12,0.32-0.12,0.65-0.12,0.65s-0.32,0.2-0.75,0.5c-0.56,0.5-1.24,0.65-1.69,0.32
					c-0.03,0.03-0.22-0.16-0.38-0.4c-0.16-0.23-0.29-0.49-0.29-0.49s0-0.86,0.31-1.67c0.15-0.4,0.35-0.79,0.54-1.06
					C300.71,118.91,300.86,118.74,300.87,118.73z"
      />

      <path
        d="M333.18,108.46c0-0.08,0-0.1,0.08-0.2c0.46-0.67,1.61-1.64,2.91,0.03c0.02,0.03,0.11,0.17,0.12,0.25l-0.14,5.16
				c-0.47,16.44-1.26,32.88-2.37,49.29l-0.89,12.31l-0.49,6.15l-0.26,3.07l-0.06,0.77l0,0.02c2.76,0.21-3.76-0.26-3.09-0.22l0-0.05
				l0.01-0.1l0.01-0.19l0.03-0.38l0.1-1.54l0.8-12.3c0.15-2.05,0.23-4.09,0.48-6.22c0.25-2.12,0.68-4.31,1.62-6.41l0,0
				c0.74-1.61,1.76-3.11,3.13-4.34c1.39-1.23,3.05-2.16,5.1-2.42c0.53-0.05,1.13-0.03,1.72,0.13c0.59,0.15,1.12,0.43,1.57,0.75
				c0.9,0.66,1.52,1.47,1.97,2.32c0.93,1.68,1.27,3.61,1.12,5.46c-0.15,1.85-0.79,3.68-1.94,5.2c-1.15,1.53-2.76,2.65-4.51,3.28
				c-1.75,0.62-3.65,0.78-5.5,0.42c-0.46-0.09-0.91-0.22-1.36-0.37l-0.66-0.26c-0.69,2.64,0.91-3.6,0.76-2.96l0.02,0l0.04,0.01
				l0.09,0.01l0.18,0.02l0.36,0.05c0.95,0.17,1.85,0.49,2.68,0.92c1.66,0.87,2.98,2.11,4.08,3.44c1.1,1.33,1.99,2.8,2.75,4.24
				c0.76,1.43,1.45,2.84,2.2,4.15c0.38,0.65,0.77,1.28,1.19,1.87l0.42,0.56l0.09,0.11c0.5,0.62,1.04,1.18,1.62,1.66
				c0,0,0.07,0.06,0.2,0.17c0.12,0.12,0.33,0.28,0.61,0.48l0,0c0.51,0.37,1.36,0.84,2.38,1.13c1.01,0.28,2.18,0.38,3.17,0.19
				c1.2-0.28,1.89-0.8,2.32-1.1c0.42-0.31,0.64-0.37,0.69-0.16c0.03,0.19-0.06,0.7-0.48,1.27c-0.4,0.58-1.12,1.23-2.07,1.64
				c0.16-0.05-0.97,0.54-2.57,0.59c-1.6,0.11-3.57-0.35-4.88-1.05l0,0c-0.52-0.26-0.94-0.54-1.24-0.75
				c-0.29-0.2-0.43-0.33-0.43-0.33c-0.37-0.31-0.7-0.65-1.02-1c-0.16-0.18-0.32-0.35-0.47-0.53l-0.22-0.27l-0.11-0.14l-0.06-0.07
				l-0.35-0.47c-1.95-2.68-3.11-5.61-4.58-8.05c-1.46-2.41-3.42-4.6-5.75-5.32c-0.29-0.07-0.58-0.16-0.88-0.19l-0.22-0.03l-0.03,0
				l-0.01,0c-0.69,2.66,0.93-3.62,0.77-2.97l0.05,0.02l0.11,0.04c0.14,0.05,0.28,0.12,0.43,0.16c0.58,0.2,1.2,0.3,1.83,0.33
				c1.25,0.05,2.54-0.21,3.65-0.78c1.11-0.57,2.04-1.4,2.65-2.47c0.62-1.06,0.96-2.32,0.95-3.58c0.07-2.43-1.62-5.28-3.26-4.67
				c-2.32,0.42-4.38,2.49-5.46,4.92l0,0c-1.51,3.34-1.52,7.44-1.83,11.55l-0.8,12.3l-0.1,1.54l-0.03,0.38l-0.01,0.19l-0.01,0.1
				l0,0.05c0.67,0.07-5.87-0.45-3.1-0.24l0-0.02l0.06-0.77l0.26-3.07l0.49-6.13l0.89-12.28c1.11-16.37,1.89-32.77,2.36-49.17
				L333.18,108.46z"
      />

      <path
        d="M348.9,109.4c-0.01-0.07-0.02-0.1,0.04-0.21c0.33-0.75,1.28-1.91,2.87-0.51c0.03,0.03,0.14,0.15,0.16,0.22l0.81,5.1
				c2.56,16.25,4.81,32.55,6.74,48.89l1.39,12.26l0.65,6.13l0.31,3.07l0.08,0.77l0,0.02c2.75-0.3-3.75,0.44-3.08,0.35l-0.01-0.05
				l-0.01-0.1l-0.02-0.19l-0.05-0.38l-0.18-1.53l-1.47-12.23c-0.23-2.04-0.52-4.06-0.68-6.2c-0.15-2.13-0.13-4.36,0.41-6.6l0,0
				c0.43-1.72,1.16-3.38,2.27-4.85c1.14-1.46,2.6-2.68,4.57-3.31c0.51-0.15,1.1-0.24,1.71-0.19c0.61,0.04,1.18,0.21,1.68,0.45
				c1,0.48,1.76,1.17,2.36,1.91c1.23,1.48,1.91,3.31,2.11,5.16c0.19,1.85-0.1,3.76-0.95,5.46c-0.85,1.72-2.23,3.12-3.83,4.05
				c-1.61,0.93-3.45,1.44-5.33,1.43c-0.47,0-0.94-0.04-1.41-0.11l-0.7-0.13c-0.19,2.72,0.23-3.71,0.2-3.05l0.02,0l0.04,0l0.09,0
				l0.18-0.01l0.36-0.01c0.96-0.01,1.91,0.14,2.81,0.41c1.79,0.55,3.32,1.53,4.64,2.63c1.32,1.1,2.47,2.39,3.48,3.66
				c1.01,1.27,1.95,2.53,2.93,3.68c0.49,0.57,0.99,1.12,1.52,1.62l0.52,0.47l0.11,0.1c0.6,0.51,1.24,0.97,1.9,1.33
				c0,0,0.08,0.05,0.23,0.13c0.14,0.1,0.37,0.22,0.68,0.36h0c0.57,0.27,1.49,0.57,2.54,0.68c1.05,0.09,2.21-0.03,3.15-0.4
				c1.13-0.49,1.71-1.14,2.08-1.5c0.36-0.38,0.56-0.48,0.65-0.28c0.06,0.18,0.07,0.7-0.24,1.34c-0.29,0.64-0.88,1.42-1.73,1.99
				c0.15-0.08-0.86,0.71-2.42,1.05c-1.55,0.4-3.57,0.31-4.99-0.13l0,0c-0.56-0.16-1.03-0.36-1.36-0.51
				c-0.32-0.15-0.48-0.24-0.48-0.24c-0.42-0.24-0.81-0.51-1.19-0.79c-0.19-0.14-0.38-0.28-0.56-0.43l-0.27-0.23l-0.13-0.11
				l-0.07-0.06l-0.43-0.39c-2.41-2.28-4.09-4.94-5.99-7.07c-1.88-2.1-4.21-3.89-6.63-4.17c-0.3-0.02-0.6-0.05-0.9-0.02l-0.22,0.01
				l-0.03,0l-0.01,0c-0.19,2.74,0.24-3.73,0.21-3.06l0.06,0.01l0.11,0.02c0.15,0.02,0.3,0.07,0.45,0.08
				c0.61,0.09,1.24,0.07,1.86-0.02c1.24-0.18,2.46-0.67,3.44-1.44c0.99-0.76,1.75-1.75,2.15-2.92c0.41-1.16,0.52-2.46,0.27-3.69
				c-0.38-2.4-2.56-4.89-4.07-3.99c-2.2,0.84-3.84,3.25-4.46,5.84l0,0c-0.87,3.56-0.13,7.6,0.33,11.69l1.47,12.23l0.18,1.53
				l0.05,0.38l0.02,0.19l0.01,0.1l0.01,0.05c0.67-0.06-5.86,0.64-3.09,0.33l0-0.02l-0.08-0.77l-0.31-3.06l-0.65-6.12l-1.39-12.23
				c-1.92-16.3-4.17-32.56-6.73-48.77L348.9,109.4z"
      />

      <path
        d="M390.4,154.07c0.03,0.03,0.03,0.05,0.01,0.14c-0.08,0.31-0.09,0.76-0.32,1.26c-0.22,0.44-0.66,0.99-1.93,0.58
				c-0.04-0.02-0.24-0.12-0.31-0.2c-0.71-1.01-1-2.22-0.99-3.33c0.01-1.12,0.29-2.19,0.75-3.15c0.57-1.21,1.49-2.29,2.72-3.02
				c1.28-0.75,2.63-0.97,3.96-0.91c2.68,0.15,4.97,1.5,6.68,3.27c1.74,1.85,2.69,4.15,3.22,6.4c0.53,2.27,0.65,4.52,0.72,6.7
				c0.11,4.35-0.06,8.72-0.51,13.05c-0.24,2.12-0.44,4.59-1.93,6.84c-1.51,2.25-4.02,3.54-6.59,3.74c-1.29,0.11-2.63-0.1-3.87-0.62
				c-1.24-0.52-2.3-1.39-3.12-2.39c-1.61-2.02-2.38-4.72-1.8-7.35c0.59-2.61,2.45-4.69,4.49-5.94c2.06-1.28,4.3-1.99,6.55-2.42
				c0.58-0.09,1.08-0.21,1.81-0.24c0.36-0.01,0.77,0,1.22,0.11c0.45,0.1,0.93,0.33,1.32,0.64c0.81,0.65,1.15,1.52,1.32,2.2
				c0.17,0.7,0.21,1.33,0.24,1.9c0.1,2.07,0.33,4.09,0.96,5.89l0.12,0.34l0.1,0.25l0.06,0.15c0.04,0.1,0.09,0.2,0.14,0.3
				c0.09,0.2,0.19,0.39,0.3,0.58c0.2,0.38,0.45,0.73,0.7,1.07c0,0,0.14,0.2,0.38,0.54c0.24,0.29,0.6,0.71,1.13,1.09
				c1.05,0.79,2.79,1.39,4.25,1.26c1.23-0.12,1.96-0.58,2.42-0.82c0.45-0.27,0.67-0.3,0.7-0.09c0.01,0.2-0.13,0.69-0.61,1.23
				c-0.47,0.53-1.26,1.09-2.25,1.39c0.11-0.01-0.43,0.19-1.32,0.27c-0.89,0.09-2.13-0.01-3.31-0.41c-1.19-0.38-2.31-1.07-3.04-1.77
				c-0.71-0.66-1.08-1.17-1.08-1.17c-0.34-0.47-0.63-0.97-0.89-1.47c-0.12-0.26-0.25-0.51-0.36-0.77l-0.08-0.19l-0.11-0.27
				c-0.06-0.14-0.1-0.28-0.15-0.42c-0.2-0.55-0.35-1.11-0.49-1.67c-0.27-1.12-0.43-2.24-0.53-3.34c-0.05-0.55-0.09-1.1-0.12-1.65
				c-0.02-0.53-0.05-0.96-0.13-1.33c-0.07-0.37-0.2-0.6-0.26-0.65c-0.06-0.05-0.05-0.08-0.35-0.1c-0.27-0.01-0.77,0.06-1.26,0.15
				c-0.48,0.08-0.97,0.18-1.44,0.3c-1.89,0.47-3.71,1.21-5.12,2.32c-0.7,0.55-1.28,1.2-1.7,1.92c-0.42,0.73-0.66,1.51-0.7,2.31
				c-0.1,1.61,0.62,3.36,1.81,4.46c1.17,1.14,2.84,1.55,4.5,1.22c1.65-0.32,3.14-1.34,3.87-2.78c0.78-1.49,0.97-3.49,1.18-5.57
				c0.4-4.13,0.54-8.29,0.41-12.44c-0.06-2.06-0.22-4.07-0.69-5.94c-0.48-1.86-1.26-3.59-2.51-4.82c-1.24-1.26-2.92-2.13-4.53-2.18
				c-0.79-0.04-1.61,0.16-2.17,0.51c-0.6,0.36-1.14,0.96-1.47,1.68C389.83,151.87,389.81,153.29,390.4,154.07z"
      />
    </svg>
  );
};

export default SVGLogo;
