import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
//
import SVGLogo from "components/svg-logo";
//
export default ({ ...rest }) => (
  <Wrap>
    <Link to="/" title="Nurmin Majakka">
      <SVGLogo />
    </Link>
  </Wrap>
);
//
const Wrap = styled.div`
  margin: 0 auto;
  height: 4rem;
`;
