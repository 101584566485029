import React from "react";
import styled from "styled-components";
//
import NavLink from "./navLink";
//
export default ({ mobileActive, onClick, items, title, ...rest }) => (
  <Wrap mobileActive={mobileActive}>
    <List>
      {items.map(item => (
        <NavLink
          key={item.id}
          {...item}
          mobileActive={mobileActive}
          onClick={onClick}
        />
      ))}
    </List>
  </Wrap>
);
//

const Wrap = styled.div`
  justify-self: center;
  text-align: center;

  a {
    padding: 0 0.4rem;
    text-transform: uppercase;
    font-size: 0.9em;
  }

  @media (min-width: 335px) {
    a {
      font-size: normal;
    }
  }

  @media (min-width: 768px) {
    a {
      padding: 0 1rem;
    }
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
`;
