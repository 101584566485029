import React from "react";
import { Root } from "react-static";
import styled from "styled-components";
//
import Meta from "containers/meta";
//
import Header from "containers/header";
import Footer from "containers/footer";
//
export default ({ children, ...rest }) => (
  <Root>
    <Meta />
    <Wrap>
      <Header {...rest} />
      {children}
    </Wrap>
    <Footer />
  </Root>
);
//
const Wrap = styled.div``;
